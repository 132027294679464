import React from 'react'
import _ from 'lodash'
import Layout from '../components/Layout'
import { TitleSection } from '../components/common/Index.styled'
import Section from '../components/common/Section'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import SectionHeaderImage from '../components/common/SectionHeaderImage'
import image_background from '../images/solutions/desarrollo.png'
import { ContractButtonSmall } from '../components/Solutions/SolutionDetail.styled'
import FooterContact from './../components/FooterContact'

const Desarrollo = props => (
  <Layout
    title="Servicio de Desarrollo Tecnológico"
    description="Mantenernos siempre a la vanguardia de las nuevas tecnologías nos permite ofrecer soluciones innovadoras y productos de la más alta calidad, diseñados a la medida de las necesidades de cada uno de nuestros clientes."
    {...props}
  >
    <SectionHeaderImage image={image_background} position="90% 0" />
    <Section>
      <TitleSection>
        <FormattedMessage id="solutions.desarrollo.title" />
      </TitleSection>
      <div>
        <p>
          <FormattedMessage id="solutions.desarrollo.content.p1" />
        </p>
        <ul>
          {_.map(_.times(2, String), (value, key) => (
            <li key={key}>
              <FormattedHTMLMessage
                id={`solutions.desarrollo.content.list.item${value}`}
              />
            </li>
          ))}
        </ul>
        {_.map(_.times(14, String), (value, key) => (
          <p key={key}>
            <FormattedMessage
              id={`solutions.desarrollo.content.second_list.item${value}`}
            />
          </p>
        ))}
        <ul>
          {_.map(_.times(2, String), (value, key) => (
            <li key={key}>
              <FormattedHTMLMessage
                id={`solutions.desarrollo.content.third_list.item${value}`}
              />
            </li>
          ))}
        </ul>
        <ContractButtonSmall to="/contacto/" small>
          <FormattedMessage id="element.button_hire" />
        </ContractButtonSmall>
      </div>
    </Section>
    <FooterContact />
  </Layout>
)

export default Desarrollo
